import Vue from 'vue';
import CallCenterUserService from '@/services/crm/call-center-user.service';
import CallCenterCallService from '@/services/crm/call-center-call.service';
import CallCenterUserLoginModel from '@/models/crm/call-center-user-login.model';
import CallCenterUserModel from '@/models/crm/call-center-user.model';
import { IWebsocketCallCenter } from '@/interfaces/call-center/websocket-call-center.interface';

export default class GoToCallCenter implements IWebsocketCallCenter {
  protected callCenterUserService: CallCenterUserService = new CallCenterUserService();

  protected callCenterCallService: CallCenterCallService = new CallCenterCallService();

  protected showNotification = false;

  protected websocketInstance: WebSocket | null = null;

  protected userPartnerInstance: CallCenterUserLoginModel | null = null;

  protected callCenterUser: CallCenterUserModel | null = null;

  protected userPartner: CallCenterUserLoginModel | null = null;

  protected websocketConnectionString = '';

  protected extensionUserCallCenter = '';

  protected idUserUserCallCenter = '';

  protected unitIdentifierCallCenter = '';

  protected makePhoneCallNumber = '';

  public subscribe: Vue;

  constructor() {
    this.subscribe = new Vue();
  }

  // Obtem o usuario Call Center Geovendas salvo
  getUserLogged = (): CallCenterUserModel | null => {
    const agentId = localStorage.getItem('callCenterAgentId');
    if (!this.callCenterUser) {
      this.callCenterUser = new CallCenterUserModel();
      this.callCenterUser.idUsuario = agentId || '';
    }
    return this.callCenterUser;
  };

  // Realiza uma chamada ativa apartir do geovendas.
  // Recemos as informações da chamada via websocket.
  makePhoneCall = (phone: string, idContato: number | null): void => {
    const user = this.getUserLogged();
    if (user == null || user.idUsuario === '') {
      this.subscribe.$notify.error('Agente não configurado, entre em contato com o suporte!');
      return;
    }

    if (phone === undefined || phone === null || phone === '') {
      this.subscribe.$notify.error('Número de telefone não informado!');
      return;
    }

    const agentId: string = user.idUsuario;
    const defaultCountryCode = '+55';
    let cleanedPhone = phone.replace(/[^0-9+]/g, '');

    if (!cleanedPhone.startsWith('+') && cleanedPhone.startsWith('55')) {
      cleanedPhone = `+${cleanedPhone}`;
    }

    if (!cleanedPhone.startsWith('+')) {
      cleanedPhone = `${defaultCountryCode}${cleanedPhone}`;
    }

    this.callCenterCallService
      .makePhoneCall({ phone: cleanedPhone, agentId, idContato })
      .then((response) => {
        if (response.status !== 200 && response.status !== 202) {
          this.subscribe.$notify.error(response.message, 3500);
          return;
        }
        this.subscribe.$notify.success('Realizando a ligação no GoTo Connect...', 2000);
      })
      .catch((error) => {
        this.subscribe.$notify.error(
          'Não foi possível realizar a ligação. Certifique-se que o número está correto e possui código de área.',
          3500,
        );
        console.error(error);
      });
  };
}
