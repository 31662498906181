import { Vue } from 'vue-property-decorator';
import { inject } from 'inversify-props';
import { IExtensionCallCenter } from '@/interfaces/call-center/extension-call-center.interface';
import CallCenterCallService from '@/services/crm/call-center-call.service';
import CallCenterUserModel from '@/models/crm/call-center-user.model';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import SessionService from '@/services/session.service';

export default class CloudTalkCallCenter implements IExtensionCallCenter {
  @inject(InjectionIdEnum.CallCenterCallService)
  protected service!: CallCenterCallService;

  @inject(InjectionIdEnum.SessionService)
  private sessionService!: SessionService;

  protected callCenterUser: CallCenterUserModel | null = null;

  subscribe: Vue;

  constructor() {
    this.subscribe = new Vue();
  }

  makePhoneCall(phone: string): void {
    const user = this.getUserLogged();
    if (user == null || user.idUsuario === '') {
      this.subscribe.$notify.error('Agente não configurado, entre em contato com o suporte!');
      return;
    }

    if (phone === undefined || phone === null || phone === '') {
      this.subscribe.$notify.error('Número de telefone não informado!');
      return;
    }

    const agentId: string = user.idUsuario;
    const callService = new CallCenterCallService();

    const defaultCountryCode = '+55';
    let cleanedPhone = phone.replace(/[^0-9+]/g, '');

    if (!cleanedPhone.startsWith('+') && cleanedPhone.startsWith('55')) {
      cleanedPhone = `+${cleanedPhone}`;
    }

    if (!cleanedPhone.startsWith('+')) {
      cleanedPhone = `${defaultCountryCode}${cleanedPhone}`;
    }

    callService
      .makePhoneCall({ phone: cleanedPhone, agentId, idContato: null })
      .then((response) => {
        if (response.status !== 200) {
          this.subscribe.$notify.error(response.message, 3500);
          return;
        }
        this.subscribe.$notify.success('Realizando a ligação no CloudTalk...', 2000);
      })
      .catch((error) => {
        this.subscribe.$notify.error(
          'Não foi possível realizar a ligação. Certifique-se que o número está correto e possui código de área.',
          3500,
        );
        console.error(error);
      });
  }

  getUserLogged = (): CallCenterUserModel | null => {
    const agentId = localStorage.getItem('callCenterAgentId');
    if (!this.callCenterUser) {
      this.callCenterUser = new CallCenterUserModel();
      this.callCenterUser.idUsuario = agentId || '';
    }
    return this.callCenterUser;
  };
}
