import EoxCallCenter from '@/services/call-center/eox-call-center.service';
import CloudTalkCallCenter from '@/services/call-center/cloudtalk-call-center.service';
import GoToCallCenter from '@/services/call-center/goto-call-center.service';

export enum CallCenterPartnerEnum {
  EOX = 10,
  CloudTalk = 20,
  GoTo = 30,
}

export function getPluginByPartnerCode(): any {
  return {
    [CallCenterPartnerEnum.EOX]: new EoxCallCenter(),
    [CallCenterPartnerEnum.CloudTalk]: new CloudTalkCallCenter(),
    [CallCenterPartnerEnum.GoTo]: new GoToCallCenter(),
  };
}
