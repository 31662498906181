import { injectable } from 'inversify-props';
import dayjs from 'dayjs';
import { classToPlain } from 'class-transformer';
import { cloneDeep } from 'lodash';
import BaseService from '@/services/base-service';
import FinancialInformationModel from '@/models/crm/financial-information.model';
import EmailModel from '@/models/email.model';
import { IKeyValue } from '@/interfaces/key-value.interface';
import { RequestConfig } from '@/interfaces/request-config.interface';
import FinancialInformationOccurrenceModel from '@/models/crm/financial-information-occurrences.model';

@injectable()
export default class FinancialInformationService extends BaseService {
  async getContracts(clientId: string, startDate: string): Promise<FinancialInformationModel[]> {
    const config: RequestConfig = {
      params: {
        cnpj: clientId,
        startDate,
      },
    };
    return (await this.httpService.get(
      FinancialInformationModel,
      `${this.getApiPath()}/crm/informacao-financeira/list`,
      config,
    )) as FinancialInformationModel[];
  }

  static generateFinancialInformationExportFilename(date: Date): string {
    return `Empresa_Titulos_${dayjs(date).format('YYYY-MM-DD_HH-mm-ss')}.xlsx`;
  }

  async getTitulos(titulos: string, codCliente: string): Promise<string[]> {
    const config: RequestConfig = {
      params: {
        titulos,
        codCliente,
      },
    };

    return (await this.httpService.get(String, `${this.getApiPath()}/crm/titulo/list`, config)) as string[];
  }

  async sendTituloByEmail(model: EmailModel<IKeyValue<string | number>[]>): Promise<EmailModel> {
    return (await this.httpService.post(
      EmailModel,
      `${this.getApiPath()}/crm/titulo/send-by-email`,
      classToPlain(cloneDeep(model)),
    )) as EmailModel;
  }

  async getOccurrences(clientId: string, codTitle: string): Promise<FinancialInformationOccurrenceModel[]> {
    const config: RequestConfig = {
      params: {
        cnpj: clientId,
        codTitle,
      },
    };
    return (await this.httpService.get(
      FinancialInformationOccurrenceModel,
      `${this.getApiPath()}/crm/informacao-financeira/ocorrencia`,
      config,
    )) as FinancialInformationOccurrenceModel[];
  }
}
